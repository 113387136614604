<template>
  <v-col>
    <v-row>
      <v-card flat style="width:100%; background-color:#F2F2F2">
        <v-row class="pa-4 mb-4" style="background-color: white">
          <v-col md="5" style="font-size:13px;color: #484848;">
            <v-row>
              자동 제안 명
            </v-row>
            <v-row class="mt-2">
              <v-select :items="propositionAutos"
                        item-text="name"
                        item-value="id"
                        :value="propositions[0].propositionAutoId"
                        height="40px"
                        hide-details outlined solo flat single-line
                        @change="changeAutoPropose">
              </v-select>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
            :headers="headersDetail"
            :items="propositions"
            item-key="id"
            :items-per-page.sync="options.itemsPerPage"
            :options.sync="options"
            :search="filter"
            :custom-filter="filterStatus"
            disable-sort
            mobile-breakpoint="0"
            :hide-default-header="$vuetify.breakpoint.smAndDown"
            hide-default-footer
            :footer-props="{
                  itemsPerPageOptions: []
                }">
          <template v-slot:header.taggableType="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.Influencer.account="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.status="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.adFee="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.point="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.reward="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.maxEditCount="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.proposedAt="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.deadline="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:header.rePropose="{header}">
            <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
          </template>
          <template v-slot:item.taggableType="props">
            <naverIcon v-if="props.item.taggableType === 'naver'"/>
            <instaIcon v-if="props.item.taggableType === 'insta'"/>
            <youtubeIcon v-if="props.item.taggableType === 'youtube'"/>
          </template>
          <template v-slot:top>
            <v-row>
              <div class="body-2 my-4 mx-4" style="color: #808080">
                {{propositions.length}}개 ・ {{Math.floor((parseInt(propositions.length)-1) / options.itemsPerPage + 1)}} Pages
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color:#484848;font-size:13px">
                      {{options.itemsPerPage}}명씩 보기
                      <v-icon small>expand_more</v-icon>
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in [30, 50, 100]" :key="index" @click="options.itemsPerPage = item">
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color:#484848;font-size:13px">
                      {{filter}}
                      <v-icon small>expand_more</v-icon>
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in ['상태 전체', '확인중', '제안 수락', '제안 거절', '무응답', '수락 후 취소']" :key="index" @click="filter = item">
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-row>
            <v-divider></v-divider>
          </template>
          <template v-slot:item.Influencer.account="{item}">
            <v-row class="py-2" @click="influencerDetailDlg(item.Influencer)">
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '2'">
                <v-avatar size="40">
                  <img v-if="item.Influencer.photo" :src="item.Influencer.photo"/>
                  <img v-else src="@/assets/img/matching-placeholder.jpg">
                </v-avatar>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '4'" class="ml-1">
                <v-row class="caption mt-3" style="color:#4770BD" :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''">
                  {{item.Influencer.account}}
                  <v-icon small color="#4770BD" class="ml-1 mb-1 hidden-sm-and-down">mdi-text-box-search-outline</v-icon>
                </v-row>
              </v-col>
              <v-col class="hidden-md-and-up mt-2 text-right" cols="3" style="font-size:12px" :style="'color: ' + statusMap[item.status || 0].color">
                <v-icon :style="'color: ' + statusMap[item.status || 0].color">{{statusMap[item.status || 0].icon}}</v-icon>
                {{statusMap[item.status || 0].text}}<br><span style="color:#3A3A3A;font-size:12px">{{item.reasonRefuse}}</span>
              </v-col>
            </v-row>
            <v-row class="hidden-md-and-up mt-2">
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  {{ {naver:'블로그 순위', youtube:'구독자', insta:'팔로워'}['naver']}}
                </v-row>
                <v-row class="caption" style="color:#808080">
                  {{parseInt(item.Influencer.rank) | numFormat}}{{{naver:'위', youtube:'', insta:''}['naver']}}
                </v-row>
              </v-col>
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  예상 광고 단가
                </v-row>
                <v-row class="caption" style="color:#808080">
                  ₩{{parseInt(item.Influencer.adFee) | numFormat}}
                </v-row>
              </v-col>
              <v-col>
                <v-row style="color:#808080;font-size:10px">
                  제안금액
                </v-row>
                <v-row class="caption" style="color:#808080">
                  ₩{{(item.point || 0) | numFormat}}
                </v-row>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.status="{item}">
            <div class="caption" style="color:#808080">
              <div :style="'color: ' + statusMap[item.status || 0].color">
                <v-icon :style="'color: ' + statusMap[item.status || 0].color">{{statusMap[item.status || 0].icon}}</v-icon>
                {{statusMap[item.status || 0].text}}<br><span style="color:#3A3A3A;font-size:12px">{{item.reasonRefuse}}</span>
              </div>
            </div>
            <v-row class="hidden-md-and-up">
              <v-col>
                <v-row class="caption" style="color:#808080">
                  0
                </v-row>
                <v-row style="color:#808080;font-size:10px">
                  제안 남은 시간
                </v-row>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.adFee="{item}">
            <v-row class="caption" style="color:#808080">
              ₩{{(item.Influencer.adFee || 0) | numFormat}}
            </v-row>
          </template>
          <template v-slot:item.point="{item}">
            <v-row class="caption" style="color:#808080">
              ₩{{(item.point || 0) | numFormat}}
            </v-row>
          </template>
          <template v-slot:item.reward="{item}">
            <v-row class="caption" style="color:#808080">
              ₩{{ (item.cprice + item.rewardFeeAmount) | numFormat}}
            </v-row>
          </template>
          <template v-slot:item.maxEditCount="{item}">
            <v-row class="caption" style="color:#808080">
              {{(item.maxEditCount || 0) | numFormat}}회
            </v-row>
          </template>
          <template v-slot:item.reward="{item}">
            <v-row class="caption" style="color:#808080">
              ₩{{ (item.cprice + item.rewardFeeAmount) | numFormat}}
            </v-row>
          </template>
          <template v-slot:item.proposedAt="props">
            <div class="caption" style="color:#808080" v-if="props.item.proposedAt">
              {{$moment(props.item.proposedAt).tz('Asia/Seoul').format('YYYY.MM.DD HH:MM')}}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.deadline="props">
            <div class="caption" style="color:#808080" v-if="props.item.proposedAt">
              {{getTimeRemaining(props.item)}}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.rePropose="{item}">
            <v-menu v-model="item.campaignAddMenu" :close-on-content-click="false" :nudge-width="200" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon color="#4770BD" class="mr-2">mdi-playlist-plus</v-icon>
                </div>
              </template>
              <v-card>
                <v-list>
                  <v-subheader style="color:#484848;font-size:13px">다음 캠페인에 추가</v-subheader>
                  <v-list-item v-if="campaigns.length === 0">
                    <v-list-item-title style="font-size:14px">
                      <router-link to="/regcam/" style="color:#484848"><v-icon>mdi-plus-circle-outline</v-icon> 새 캠페인 추가</router-link>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-for="(campaign, index) in campaigns" @click="updateToProposition(campaign, item)" :key="index">
                    <v-list-item-title style="font-size:14px" :style="checkProposition(campaign, item.Influencer) === 2 ? 'color:#c2c2c2' : 'color:#484848'">{{ campaign.name }}
                      <v-chip outlined small color="#757575" v-if="checkProposition(campaign, item.Influencer) === 2">제안 중</v-chip>
                      <v-chip outlined small color="#BD1328" v-else-if="checkProposition(campaign, item.Influencer)">삭제</v-chip>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination
              v-model="options.page"
              :length="Math.floor((parseInt(propositions.length)-1) / options.itemsPerPage + 1)"
              :total-visible="10"
          ></v-pagination>
        </div>
      </v-card>
    </v-row>
    <v-row justify="center">
      <InfluencerDetail v-model="openInfluencerDetail" :openInfluencer="openInfluencer"></InfluencerDetail>
    </v-row>
  </v-col>
</template>

<script>
  import userMixin from '@/components/shared/userMixin'
  import campaignMixin from '@/components/shared/campaignMixin'
  import InfluencerDetail from "@/components/campaign/dialog/InfluencerDetail";

  export default {
    name: 'Autoresultdetail',
    mixins: [userMixin, campaignMixin],
    props: ['propositions', 'propositionAutos'],
    components: {
      naverIcon: () => import('@/assets/img/campaign/naver.svg'),
      instaIcon: () => import('@/assets/img/campaign/insta.svg'),
      youtubeIcon: () => import('@/assets/img/campaign/youtube.svg'),
      InfluencerDetail
    },
    data() {
      return {
        headersDetail_desktop: [
          { text: '채널', value: 'taggableType', width: '52px'},
          { text: '채널 정보', value: 'Influencer.account', width: '200px' },
          { text: '수락 여부', value: 'status'},
          { text: '예상 광고 단가', value: 'adFee'},
          { text: '제안 금액', value: 'point'},
          { text: '구매지원금', value: 'reward'},
          { text: '수정 가능 횟수', value: 'maxEditCount'},
          { text: '제안 일시', value: 'proposedAt', width: '140px'},
          { text: '남은시간', value: 'deadline'},
          { text: '직접 제안', value: 'rePropose'},
        ],
        headersDetail_mobile: [
          { text: '채널', value: 'taggableType', width: '52px'},
          { text: '채널 정보', value: 'Influencer.account', width: '200px' }
        ],
        datas1: [],
        options: { page: 1, itemsPerPage: 30 },
        selectedCampaign: 0,
        filter: '상태 전체',
        openInfluencer: {},
        openInfluencerDetail: false,
        statusMap: {
          0: {text: '제안 예정', icon: '', color: ''},
          1: {text: '응답 대기 중', icon: 'mdi-dots-horizontal-circle', color: '#C2C2C2'},
          2: {text: '제안 수락', icon: 'mdi-check-underline-circle', color: '#34CD89'},
          3: {text: '제안 거절', icon: 'mdi-minus-circle', color: '#BD1328'},
          4: {text: '무응답', icon: 'mdi-minus-circle', color: '#BD1328'},
          10: {text: '제안 수락', icon: 'mdi-check-underline-circle', color: '#34CD89'},
          12: {text: '제안 수락', icon: 'mdi-check-underline-circle', color: '#34CD89'},
          20: {text: '제안 수락', icon: 'mdi-check-underline-circle', color: '#34CD89'},
          22: {text: '제안 수락', icon: 'mdi-check-underline-circle', color: '#34CD89'},
          24: {text: '제안 수락', icon: 'mdi-check-underline-circle', color: '#34CD89'},
          30: {text: '제안 수락', icon: 'mdi-check-underline-circle', color: '#34CD89'},
          32: {text: '제안 수락', icon: 'mdi-check-underline-circle', color: '#34CD89'},
          40: {text: '평가 완료', icon: 'mdi-check-underline-circle', color: '#34CD89'},
          50: {text: '수락 후 취소', icon: 'mdi-minus-circle', color: '#BD1328'}
        }
      }
    },
    computed: {
      headersDetail() {
        return this.$vuetify.breakpoint.mdAndUp ? this.headersDetail_desktop : this.headersDetail_mobile;
      }
    },
    methods: {
      changeAutoPropose(id) {
        let propositionAuto = this._.find(this.propositionAutos, {id: id});
        this.$emit('detail:change', propositionAuto);
      },
      getTimeRemaining(item) {
        // this.$moment.duration(this.$moment(item.proposedAt).startOf('hour').add(25, 'h').diff(this.$moment())).humanize()
        let diff = this.$moment(item.proposedAt).startOf('hour').add(13, 'h').diff(this.$moment());
        let duration = this.$moment.duration(diff);
        return duration > 0 ? Math.floor(duration.asHours()) + this.$moment.utc(diff).format(":mm:ss") : 0;
      },
      filterStatus(value, search, item) {
        return value != null &&
            search != null && (search === '상태 전체' ||
                (item.status || 0) <= 1 && search === '확인중' ||
                (item.status || 0) === 3 && search === '제안 거절' ||
                (item.status || 0) === 4 && search === '무응답' ||
                (item.status || 0) === 50 && search === '수락 후 취소' ||
                ((item.status || 0) === 2 || (item.status || 0) >= 10) && search === '제안 수락')
      },
      checkProposition(campaign, influencer) {
        if (campaign) {
          if (this._.find(this.propositions, (proposition) => {
            return (proposition.campaignId === campaign.id
                && proposition.taggableId === influencer.id
                && proposition.taggableType === 'naver'
                && proposition.status === 1);
          })) {
            return 2
          }
          else {
            return this._.find(this.propositions, (proposition) => {
              return (proposition.campaignId === campaign.id
                  && proposition.taggableId === influencer.id
                  && proposition.taggableType === 'naver'
                  && proposition.status < 2);
            });
          }
        }
        else {
          return this.campaigns.some((campaign) => {
            return this._.find(this.propositions, (proposition) => {
              return (proposition.campaignId === campaign.id
                  && proposition.taggableId === influencer.id
                  && proposition.taggableType === 'naver'
                  && proposition.status < 2);
            });
          })
        }
      },
      async updateToProposition(campaign, orgProposition) {
        let influencer = orgProposition.Influencer;
        if (influencer.uid) {
          this.completeDialogTxt1 = campaign.name;
          let oldProposition = this.checkProposition(campaign, influencer);
          if (!oldProposition) {
            let maxEditCount = this.$getMaxEditCount(influencer.price);
            let proposition = {
              campaignId: campaign.id,
              taggableId: influencer.id,
              taggableType: 'naver',
              uid: influencer.uid,
              point: orgProposition.point || influencer.price,
              cname: campaign.serviceName,
              creward: campaign[orgProposition.taggableType].reward,
              cprice: campaign[orgProposition.taggableType].rewardPrice,
              category: campaign.category,
              status: 0,
              maxEditCount: maxEditCount,
              thumbnail: campaign.thumbnail,
              periodUse: this.$periodMap[orgProposition.taggableType][campaign.type][campaign.category.firstName][0],
              periodFirstCopy: this.$periodMap[orgProposition.taggableType][campaign.type][campaign.category.firstName][1]
            }
            if (campaign[proposition.taggableType].rewardPrice && campaign.deliveryMethod === 'PR') {
              proposition.rewardFees = 13.5;
              proposition.rewardFeeAmount = campaign[proposition.taggableType].rewardPrice * proposition.rewardFees * 0.01;
            }
            await this.axios.post('/campaign/proposition', proposition);
            this.completeDialogTitle = '추가 완료';
            this.completeDialogTxt2 = '제안목록에 추가되었습니다.';
            this.showCompleteDialog = true;
          } else if (oldProposition !== 2) {
            await this.axios.delete('/campaign/proposition?id=' + oldProposition.id);
            this.completeDialogTitle = '삭제 완료';
            this.completeDialogTxt2 = '제안목록에서 삭제되었습니다.';
            this.showCompleteDialog = true;
          }
          this.$emit('update:propositions', this.propositions);
        }
        else {
          this.$store.commit('setMsg', '현재 제안이 불가능한 유저입니다.');
          this.$store.commit('setShowSnackbar', true);
        }
      },
      async influencerDetailDlg(item, event) {
        console.log(event)
        this.openInfluencerDetail = true;
        this.openInfluencer = item;
      },
    }
  };
</script>
